import { render, staticRenderFns } from "./Attachments.vue?vue&type=template&id=dc9e9ff2&scoped=true&"
import script from "./Attachments.vue?vue&type=script&lang=ts&"
export * from "./Attachments.vue?vue&type=script&lang=ts&"
import style0 from "./Attachments.vue?vue&type=style&index=0&id=dc9e9ff2&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "dc9e9ff2",
  null
  
)

export default component.exports